import React from 'react'
import Card from './Card'
import './Cards.css'

function Cards() {
    return (
        <>
        <div className="cards">
            <div className="cards__title">
                <h1>My Work</h1>
            </div>
            <div className="cards__container">
                <div className="cards_wrapper">
                    <ul className="cards__items">
                        <Card
                        src="/images/gif_gerstner.gif"
                        text="Frog Wars"
                        path="/services"
                        />
                        <Card
                        src="/images/FrogWars_Scenary_01.png"
                        text="Meltrozo Sister's Pit Stop"
                        path="/services"
                        />
                        <Card
                        src="/images/card_GlobalGameJam20.png"
                        text="Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox"
                        path="/services"
                        />
                    </ul>
                    <ul className="cards__items">
                        <Card
                        src="/images/gif_gerstner.gif"
                        text="Frog Wars"
                        path="/services"
                        />
                        <Card
                        src="/images/gif_elementals.gif"
                        text="Meltrozo Sister's Pit Stop"
                        path="/services"
                        />
                        <Card
                        src="/images/gif_barco.gif"
                        text="Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox Detox"
                        path="/services"
                        />
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}

export default Cards
