//TODO Qué es {useState}? Por que usar eso en lugar de un Class Component
import React, {useState, useEffect} from 'react'
//TODO Qué es <Link>
//TODO Qué es 'react-router-dom'
import {Link} from 'react-router-dom';
import './Navbar.css'
import { Button } from './Button';

//TODO What is the googleapis link in public/index.html
//TODO What is the fontawesome link in public/index.html
//TODO https://www.youtube.com/watch?v=I2UBjN5ER4s
//TODO //BUG Los botones del menu mobile se ven demasiado abajo
function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    //The CONTACT button is not visible on mobile
    const updateButtonVisibility = () => {
        if(window.innerWidth <= 960){
            setButton(false);
        }else{
            setButton(true);
        }
    }
    useEffect(()=>{
        updateButtonVisibility()
    }, [])

    window.addEventListener('resize', updateButtonVisibility);

    return (
        <>
            <nav className="navbar" >
                <div className="navbar-container">
                    <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                        {/* <i class="far fa-user"/>Filoflow */}
                        <img className="navbar-avatar" src="/images/logo_BW.png"/> GuillermoVFX 
                    </Link>
                    <div className='menu-icon' onClick={handleClick}>
                        <i className={click?'fas fa-times': 'fas fa-bars'}/>
                    </div>
                    <ul className ={click?'nav-menu active' : 'nav-menu'}>
                        <li className = 'nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                Home
                            </Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to="/products" className='nav-links' onClick={closeMobileMenu}>
                                My Work
                            </Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to="/nav-items" className='nav-links' onClick={closeMobileMenu}>
                                About Me
                            </Link>
                        </li>
                        <li className = 'nav-item'>
                            <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                                Contact me
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    )
}

export default Navbar
