import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import './Footer.css'

function Footer() {
    return (
        <div className="footer-container">
            <section className="footer-subscription">
                <p className="footer-subscription-heading">
                CONTACT ME
                </p>
                <p className="footer-subscription-text">
                    guillermo.couceiro@gmail.com
                </p>
                <div className="input-areas">
                    <form>
                        <input type="email" name="email" placeholder="Your Email" className="footer-input"/>
                        <Button buttonStyle="btn--online">Send</Button>
                    </form>
                </div>
            </section>
            {/* //TODO que mierda es el emmet abreviation? */}
            <div className="footer-links">
                {/* //TODO Repeated inside wrapper or outside wrapper? */}
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to="/sign-up">How it works</Link>
                        <Link to="/sign-up">Testimonials</Link>
                        <Link to="/sign-up">Careers</Link>
                        <Link to="/sign-up">Investors</Link>
                        <Link to="/sign-up">Terms of Services</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to="/sign-up">How it works</Link>
                        <Link to="/sign-up">Testimonials</Link>
                        <Link to="/sign-up">Careers</Link>
                        <Link to="/sign-up">Investors</Link>
                        <Link to="/sign-up">Terms of Services</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to="/sign-up">How it works</Link>
                        <Link to="/sign-up">Testimonials</Link>
                        <Link to="/sign-up">Careers</Link>
                        <Link to="/sign-up">Investors</Link>
                        <Link to="/sign-up">Terms of Services</Link>
                    </div>
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to="/sign-up">How it works</Link>
                        <Link to="/sign-up">Testimonials</Link>
                        <Link to="/sign-up">Careers</Link>
                        <Link to="/sign-up">Investors</Link>
                        <Link to="/sign-up">Terms of Services</Link>
                    </div>
                </div>
            </div>
            <section className="social-media">
                <div className="social-media-wrap">
                    <div className="footer-logo">
                        <Link className="social-logo">
                            TRVL <i className="fab fa-typo3"/>
                        </Link>
                    </div>
                    <small className="website-rights">
                        TRVL copyright 2020
                    </small>
                    <div className="social-icons">
                        <Link className="social-icon-link facebook"
                        to="/"
                        target="_blank"
                        aria-label="Facebook"
                        >
                            <i class="fab fa-facebook-f"/>
                        </Link>
                        <Link className="social-icon-link instagram"
                        to="/"
                        target="_blank"
                        aria-label="Instagram"
                        >
                            <i class="fab fa-instagram"/>
                        </Link>
                        <Link className="social-icon-link twitter"
                        to="/"
                        target="_blank"
                        aria-label="Twitter"
                        >
                            <i class="fab fa-twitter"/>
                        </Link>
                        <Link className="social-icon-link linkedin"
                        to="/"
                        target="_blank"
                        aria-label="LinkedIn"
                        >
                            <i class="fab fa-linkedin"/>
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Footer
