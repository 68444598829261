import React from 'react'
import {Link} from 'react-router-dom'
import './Card.css'


function Card(props) {
    return (
        <div className="Card">
            <Link className="Card__link" to={props.path}>
                <figure className="Card__pic-wrap">
                    <img src={props.src} alt="" className="Card__img"/>
                </figure>
                <div className="Card__info">
                    <h5 className="Card__text">
                        {props.text}
                    </h5>
                </div>
            </Link>
        </div>
    )
}

export default Card
