import React from 'react'
//TODO Is this needed?
import '../App.css'
import { Button } from './Button'
import './HeroSection.css'

function HeroSection() {
    return (
        <div className='hero-container'>
            {<video src="/videos/waylanders-coast.mp4" autoPlay loop muted />}
            <div className="name-card">
            <h1>Realtime VFX</h1>
            <h2>Guillermo Couceiro Salido</h2>
            </div>
            <div className ="hero-btns">
                <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'>
                    My Projects
                </Button>
                <Button
                className='btns'
                buttonStyle='btn--primary'
                buttonSize='btn--large'>
                    Contact me
                </Button>
            </div>
        </div>
    )
}

export default HeroSection
